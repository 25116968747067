import { FC } from 'react'
import { Popover, Tooltip, Whisper } from 'rsuite'
import { OverlayTriggerType } from 'rsuite/esm/Overlay/OverlayTrigger'
import useTranslation from 'next-translate/useTranslation'

import styles from '../../styles/CustomPricing.module.less'
import nameTagContractStyles from '../../styles/CustomPricing/NameTagContract.module.less'

interface NameTagContractProps {
  name: string
  trigger?: OverlayTriggerType
  tooltipText?: string
  customStyles?: string
  customStyleFirstLetter?: string
}

const NameTagContract: FC<NameTagContractProps> = (props) => {
  const {
    name,
    tooltipText,
    trigger = 'hover',
    customStyles = '',
    customStyleFirstLetter = '',
  } = props
  const firstChar = name?.[0]?.toLocaleUpperCase()
  const { t } = useTranslation('products')

  return (
    <Whisper
      trigger={trigger}
      placement="bottom"
      speaker={(
        <Tooltip arrow={false} as={Popover}>
          <div>{tooltipText && <p>{tooltipText}</p>}</div>
        </Tooltip>
      )}
    >
      <div className={`${nameTagContractStyles['contract-name-tag']} ${styles.center} ${customStyles}`}>
        {firstChar && (
          <div className={`${styles['name-tag']} ${customStyleFirstLetter}`}>
            <span>
              {firstChar.trim()}
            </span>
          </div>
        )}
        <p className={`${nameTagContractStyles['contract-price']}`}>{t('Contract Price')}</p>
      </div>
    </Whisper>
  )
}

export default NameTagContract
